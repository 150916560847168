import React, { createContext,useState, useReducer, useEffect } from 'react';
import { CelesteReducer } from './reducer';

export const CelesteContext = createContext();

const CelesteContextProvider = ({children}) => {
  const initState = {
    collection: [],
    favor: []
  }
  const [hasInit, setHasInit] = useState(false);
  const [celeste, dispatch] = useReducer(CelesteReducer, initState);
  useEffect(()=>{
    const localData = localStorage.getItem('celeste');
    const localCeleste = localData ? JSON.parse(localData) : initState;
    const { collection=[],favor=[]} = localCeleste;
    if(Array.isArray(collection) && Array.isArray(favor)){
      if(collection.length || favor.length){
        dispatch({
          type: 'UPDATE_LOCAL_DATA',
          value: localCeleste
        })
      }
    }
    setHasInit(true);
  },[])
  useEffect(() => {
    if(hasInit){
      localStorage.setItem('celeste', JSON.stringify(celeste));
    }
  }, [celeste]);
  return (
    <CelesteContext.Provider value={{ celeste, dispatch }}>
      {children}
    </CelesteContext.Provider>
  );
}
 
export default CelesteContextProvider;
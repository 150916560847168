import React, { useState, useContext } from "react"
import styled from "styled-components"

import useFormatLocale from "@/hooks/useFormatLocale"
import Image from "@/components/Image"
import { DIY_CARD_COLORS } from "@/utils/constants"
import { CelesteContext } from "@/contexts/Celeste"

const DIYRecipeCard = ({ recipe }) => {
  const lng = useFormatLocale()
  const { name, img, theme, customize, isCollected, isLiked, bg } = recipe
  const styledCard = DIY_CARD_COLORS[theme]
  const { dispatch } = useContext(CelesteContext)

  const handleCheckbox = () => {
    if (isCollected) {
      dispatch({ type: "REMOVE_MARK_COLLECTED", id: recipe.id })
    } else {
      dispatch({ type: "ADD_MARK_COLLECTED", id: recipe.id })
    }
  }

  const handleFavors = () => {
    if (isLiked) {
      dispatch({ type: "REMOVE_MARK_FAVOR", id: recipe.id })
    } else {
      dispatch({ type: "ADD_MARK_FAVOR", id: recipe.id })
    }
  }

  return (
    <StyledDIYRecipe bg={bg}>
      <div
        style={{
          borderRadius: `2px`,
          boxShadow: `0 0 0 6px ${styledCard.border}`,
          backgroundColor: `${styledCard.border}`,
        }}
      >
        <div className="recipe-bg">
          {customize && (
            <div className="recipe-brush_icon">
              <BrushIcon fill={styledCard.icon} />
            </div>
          )}
          <div className="recipe-img">
            <Image src={img.childImageSharp.fluid} />
          </div>
          <div className="recipe-favorite" onClick={handleFavors}>
            <FavoriteMarkIcon isFavorited={isLiked} color={styledCard} />
          </div>
          {isCollected && (
            <div className="recipe-storage">
              <StorageMarkIcon color={styledCard} />
            </div>
          )}
          <div
            onClick={handleCheckbox}
            style={{
              boxShadow: `0 0 0 3px ${styledCard.fill}`,
            }}
            className="recipe-checkbox"
          >
            <CheckMarkIcon isMark={isCollected} color={styledCard} />
          </div>
        </div>
      </div>
      <span className="recipe-title">{name[lng]}</span>
    </StyledDIYRecipe>
  )
}

export default React.memo(DIYRecipeCard)

const StyledDIYRecipe = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 15px;
  @media (max-width: 450px) {
    width: calc(50vw - 20px);
  }
  .recipe-title {
    margin-top: 18px;
    max-width: 124px;
    text-align: center;
  }
  .recipe-bg {
    width: 124px;
    height: 184px;
    background-size: cover;
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: 4px;
    background-image: url(${(props) => props.bg});
    .recipe-img {
      cursor: pointer;
      width: 72%;
      & > div {
        width: 100%;
      }
    }
    .recipe-brush_icon {
      position: absolute;
      top: 3px;
      left: 3px;
      width: 20px;
      height: 20px;
      svg {
        width: 100%;
        height: 100%;
      }
    }
    .recipe-favorite {
      position: absolute;
      top: 3px;
      right: 3px;
      width: 20px;
      height: 20px;
      cursor: pointer;
      svg {
        width: 100%;
        height: 100%;
        path {
          transition: 0.3s fill;
        }
      }
    }
    .recipe-storage {
      position: absolute;
      bottom: 6px;
      left: 3px;
      width: 20px;
      height: 20px;
      cursor: pointer;
      svg {
        width: 100%;
        height: 100%;
      }
    }
    .recipe-checkbox {
      position: absolute;
      right: 6px;
      bottom: 6px;
      width: 17px;
      height: 17px;
      border-radius: 2px;
      cursor: pointer;
      background-color: transparent;
      svg {
        position: absolute;
        left: -4px;
        top: -2px;
        width: 26px;
        path {
          transition: 0.3s fill;
        }
      }
    }
  }
`

const BrushIcon = ({ fill }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" version="1.0" viewBox="0 0 49 47">
      <g fill={fill} transform="translate(0.000000, -1.000000)">
        <g transform="translate(24.800000, 24.500000) scale(-1, 1) rotate(-180.000000) translate(-24.800000, -24.500000) translate(0.300000, 0.500000)">
          <path d="M43.1,45.9 C45.5,43.5 45,40.2 41.7,37 C40.1,35.4 38.7,33.7 38.7,33.3 C38.7,32.8 40.7,31.2 43.2,29.7 C45.7,28.2 48,26 48.3,24.9 C48.8,22.8 46.6,17.2 44.9,16.1 C44.3,15.7 15.5,31.3 14,32.9 C13.8,33.1 14.7,34.7 15.9,36.5 C19.2,41.3 21.6,41.8 27.1,38.9 C32.2,36.2 33.7,36.4 33.7,39.9 C33.7,42.9 37.3,47.5 39.7,47.5 C40.7,47.5 42.3,46.8 43.1,45.9 Z M28.2,21.3 C36.2,16.7 42.6,12.6 42.6,12 C42.4,9.5 36.8,1.9 34.5,1.1 C32.3,0.3 30.3,1.1 21.1,6.3 C15.1,9.7 10.8,12.5 11.5,12.5 C13.1,12.5 17.7,17 17.7,18.6 C17.7,19.3 16.6,18.6 15.2,17.1 C12.2,13.9 7.8,13.7 3.1,16.6 C-0.1,18.5 -0.8,21 2,19.9 C3.8,19.3 8.4,23 10.2,26.6 C11.1,28.2 12.2,29.5 12.7,29.5 C13.3,29.5 20.2,25.8 28.2,21.3 Z M28.2,21.3 C36.2,16.7 42.6,12.6 42.6,12 C42.4,9.5 36.8,1.9 34.5,1.1 C32.3,0.3 30.3,1.1 21.1,6.3 C15.1,9.7 10.8,12.5 11.5,12.5 C13.1,12.5 17.7,17 17.7,18.6 C17.7,19.3 16.6,18.6 15.2,17.1 C12.2,13.9 7.8,13.7 3.1,16.6 C-0.1,18.5 -0.8,21 2,19.9 C3.8,19.3 8.4,23 10.2,26.6 C11.1,28.2 12.2,29.5 12.7,29.5 C13.3,29.5 20.2,25.8 28.2,21.3 Z"></path>
        </g>
      </g>
    </svg>
  )
}

const FavoriteMarkIcon = ({ isFavorited, color }) => {
  const mainColor = isFavorited ? "#f9c50a" : "none"
  const lineColor = isFavorited ? "#b48810" : color.fill
  return (
    <svg xmlns="http://www.w3.org/2000/svg" version="1.0" viewBox="0 0 54 52">
      <g transform="translate(0.000000, -1.000000)">
        <g transform="translate(27.454545, 26.800000) scale(-1, 1) rotate(-180.000000) translate(-27.454545, -26.800000) translate(0.954545, 0.800000)">
          <path
            fill={lineColor}
            d="M21.5,50 C20.2,49 18.4,46.1 17.5,43.7 C15.8,39.4 15.7,39.3 9.9,38.6 C2.6,37.6 0,35.4 0,29.7 C0,26.1 0.6,25.1 4.5,21.8 L8.9,18 L7.7,12.9 C6.3,6.9 7.2,3.7 11,1.2 C14,-0.8 18.3,-0.2 22.5,2.8 C26,5.3 27,5.3 30.5,2.8 C34.7,-0.2 39,-0.8 42,1.2 C45.8,3.7 46.7,6.9 45.3,12.9 L44.1,18 L48.5,21.8 C52.4,25.1 53,26.1 53,29.7 C53,35.4 50.4,37.6 43.1,38.6 C37.3,39.3 37.2,39.4 35.5,43.7 C33.5,49 30.3,51.8 26.5,51.8 C25,51.8 22.7,51 21.5,50 Z M27.8,46.2 C28.6,45.9 30.3,43 31.5,39.7 C33.7,33.9 33.8,33.8 37.5,33.8 C49,33.8 50.7,31.2 43.6,24.7 L38.5,20.1 L39.9,14 C41.1,8.9 41.1,7.6 39.9,6.1 C38.5,4.1 39.2,3.9 29.5,9.6 C26.6,11.2 26.4,11.2 23.5,9.6 C13.8,3.9 14.5,4.1 13.1,6.1 C11.9,7.6 11.9,8.9 13.1,14 L14.5,20.1 L9.4,24.7 C2.3,31.2 4,33.8 15.5,33.8 C19.2,33.8 19.3,33.9 21.5,39.8 C22.7,43 24.2,45.9 24.9,46.2 C25.5,46.4 26.1,46.7 26.2,46.7 C26.3,46.8 27,46.5 27.8,46.2 Z"
          ></path>
          <path
            fill={mainColor}
            d="M27.8,46.2 C28.6,45.9 30.3,43 31.5,39.7 C33.7,33.9 33.8,33.8 37.5,33.8 C49,33.8 50.7,31.2 43.6,24.7 L38.5,20.1 L39.9,14 C41.1,8.9 41.1,7.6 39.9,6.1 C38.5,4.1 39.2,3.9 29.5,9.6 C26.6,11.2 26.4,11.2 23.5,9.6 C13.8,3.9 14.5,4.1 13.1,6.1 C11.9,7.6 11.9,8.9 13.1,14 L14.5,20.1 L9.4,24.7 C2.3,31.2 4,33.8 15.5,33.8 C19.2,33.8 19.3,33.9 21.5,39.8 C22.7,43 24.2,45.9 24.9,46.2 C25.5,46.4 26.1,46.7 26.2,46.7 C26.3,46.8 27,46.5 27.8,46.2 Z"
          ></path>
        </g>
      </g>
    </svg>
  )
}

const StorageMarkIcon = ({ color }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" version="1.0" viewBox="0 0 45 45">
      <g transform="translate(-1.000000, -1.000000)">
        <g transform="translate(23.880366, 23.027172) scale(-1, 1) rotate(-180.000000) translate(-23.880366, -23.027172) translate(0.880366, 0.527172)">
          <path
            fill={color.icon}
            d="M34.4,40 C39.5,37.7 43.9,35.3 44.3,34.7 C44.7,34.2 45.1,28.5 45.1,22.2 C45.1,15.9 44.7,10.2 44.3,9.7 C43.1,7.9 25.4,0.2 22.6,0.2 C19.8,0.2 2.1,7.9 0.9,9.7 C-0.1,11 -0.1,33.4 0.9,34.7 C2.2,36.5 19.5,44.1 22.4,44.2 C24,44.2 29.4,42.3 34.4,40 Z"
          ></path>
          <path
            fill={color.fill}
            d="M17.6,39.2 C15.4,38.2 11.4,36.4 8.6,35.3 C2.9,32.8 2.7,33 14.7,27.7 L22.7,24.1 L31.9,28.3 C37,30.6 40.8,32.8 40.4,33.2 C39.4,34.2 23.2,41.2 22.3,41.1 C21.9,41.1 19.8,40.2 17.6,39.2 Z"
          ></path>
          <path
            fill={color.fill}
            d="M6.1,23.8 C6.1,22.6 10.8,20.2 13.2,20.2 C15.5,20.2 13.7,23.1 11,24.1 C6.8,25.5 6.1,25.5 6.1,23.8 Z"
          ></path>
        </g>
      </g>
    </svg>
  )
}

const CheckMarkIcon = ({ isMark, color }) => {
  const mainColor = isMark ? color.icon : color.fill
  const lineColor = isMark ? color.fill : color.bg
  return (
    <svg xmlns="http://www.w3.org/2000/svg" version="1.0" viewBox="0 0 59 47">
      <g transform="translate(0.000000, -1.000000)">
        <g transform="translate(29.500000, 24.180919) scale(-1, 1) rotate(-180.000000) translate(-29.500000, -24.180919) translate(0.000000, 0.680919)">
          <path
            fill={lineColor}
            d="M47,46.3 C46.2,46 40.4,41.4 34.2,36.2 L22.9,26.7 L19.7,29.8 C16.1,33.3 10.8,34.1 7.7,31.8 C4.5,29.3 0,22.7 0,20.4 C0,15.8 16.9,0 21.8,0 C24.7,0 30.1,5.2 48.8,25.8 C58.7,36.7 59.8,39.6 56.3,43.9 C54.4,46.1 49.6,47.4 47,46.3 Z"
          ></path>
          <path
            fill={mainColor}
            d="M52,40.5 C52.6,39.7 52.8,38.5 52.4,37.8 C50.7,35 22.7,5 21.8,5 C20.3,5 5,19 5,20.3 C5,22.1 11.2,28 13.1,28 C14.1,28 16.3,26.2 18,24 C19.7,21.8 21.6,20 22.1,20 C22.7,20 28.9,24.9 36.1,31 C43.2,37.1 49.5,42 49.9,42 C50.4,42 51.3,41.3 52,40.5 Z"
          ></path>
        </g>
      </g>
    </svg>
  )
}

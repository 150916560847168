import React, { useState, useContext } from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "@/components/Layout"
import Image from "@/components/Image"
import DIYRecipeCard from "@/components/DIYRecipeCard"
import { Tabs,MultiLineTabs, TextTab } from "@/components/Tabs"
import HorizontalRule from "@/components/HorizontalRule"
import NoMatch from "@/components/NoMatch"

import useFormatLocale from "@/hooks/useFormatLocale"
import useTranslation from "@/hooks/useTranslation"

import CelesteContextProvider, { CelesteContext } from "@/contexts/Celeste"

import SVGToBgString from "@/utils/DIYCardBg"
import createNodeId from "@/utils/createNodeId"

const DiyRecipesPage = () => {
  const queryData = useStaticQuery(graphql`
    query {
      allCelesteYaml {
        nodes {
          name
          avatar {
            childImageSharp {
              fluid(maxWidth: 300) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          starFragments {
            name {
              en
              ja
              ko
              zh_hans
              zh_hant
            }
            img {
              childImageSharp {
                fluid(maxWidth: 300) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            availabile {
              zh_hans
              ko
              ja
              en
              zh_hant
            }
          }
          diyRecipes {
            name {
              en
              ja
              zh_hans
              ko
              zh_hant
            }
            NFS
            VFX
            customize
            source
            customize
            theme
            img {
              childImageSharp {
                fluid(maxWidth: 300) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  `)

  const t = useTranslation()

  const {
    starFragments,
    diyRecipes,
    ...celesteInfo
  } = queryData.allCelesteYaml.nodes[0]

  const diyRecipesHasMarked = diyRecipes.map((diyRecipe) => ({
    ...diyRecipe,
    isCollected: false,
    isLiked: false,
    id: createNodeId(diyRecipe.name.en, `acnh`),
    bg: SVGToBgString(diyRecipe.theme),
  }))

  const [value, setValue] = useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <Layout>
      <PageContainer>
        <div className="header">
          <Image
            className="img"
            src={celesteInfo.avatar.childImageSharp.fluid}
          />
          <span>{t(celesteInfo.name)}</span>
        </div>
        <div className="star_fragment">
          {starFragments.map((fragment, i) => (
            <StarFragment fragment={fragment} key={i} />
          ))}
        </div>
        <HorizontalRule />
        <MultiLineTabs value={value} onChange={handleChange}>
          <TextTab label="all" />
          <TextTab label="notCollected" />
          <TextTab label="collected" />
          <TextTab label="favor" />
        </MultiLineTabs>
        <CelesteContextProvider>
          <DIYRecipeCards recipes={diyRecipesHasMarked} tabs={{ value }} />
        </CelesteContextProvider>
      </PageContainer>
    </Layout>
  )
}

export default DiyRecipesPage

const PageContainer = styled.div`
  padding: 0 32px;
  min-height: calc(100vh - 72px - 116px);
  @media (max-width: 450px) {
    padding: 0 20px;
  }
  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 14px 0 18px;
    .img {
      width: 150px;
      border-radius: 50%;
    }
    span {
      margin: 14px 0 18px;
    }
  }
  .select {
    padding: 18px 0 14px;
    span {
      margin: 0 20px;
      display: inline-block;
      position: relative;
      cursor: pointer;
      &.active {
        &::after {
          content: "";
          position: absolute;
          left: -10%;
          bottom: 0;
          width: 120%;
          height: 8px;
          background: var(--acnh-colors-background-picker);
          z-index: -1;
          border-radius: 2px;
        }
      }
    }
  }
  .star_fragment,
  .star_diy_recipes {
    display: flex;
    flex-wrap: wrap;
  }
`

const StarFragment = ({ fragment }) => {
  const lng = useFormatLocale()
  return (
    <StyledStarFragment>
      <Image className="img" src={fragment.img.childImageSharp.fluid} />
      <span className={lng}>{fragment.name[lng]}</span>
      <span className={lng}>{fragment.availabile[lng]}</span>
    </StyledStarFragment>
  )
}

const StyledStarFragment = styled.div`
  min-width: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0;
  @media (max-width: 450px) {
    font-size: 14px;
  }
  .img {
    width: 40px;
  }
  span {
    text-align: center;
    width: 200px;
    @media (max-width: 450px) {
      width: calc(50vw - 20px);
    }
  }
`

const DIYRecipeCards = ({ recipes, tabs }) => {
  const { celeste } = useContext(CelesteContext)

  const currentRecipes = React.useMemo(() => {
    const { value } = tabs
    const { collection, favor } = celeste

    const recipesHasMarked = recipes.map((recipe) => {
      const recipeHasCollected = collection.filter((id) => id === recipe.id)
      const recipeHasLiked = favor.filter((id) => id === recipe.id)
      return {
        ...recipe,
        isCollected: recipeHasCollected.length ? true : false,
        isLiked: recipeHasLiked.length ? true : false,
      }
    })

    let recipesArr = [...recipesHasMarked]

    if (value === 1) {
      recipesArr = recipesHasMarked.filter((recipe) => !recipe.isCollected)
    } else if (value === 2) {
      recipesArr = recipesHasMarked.filter((recipe) => recipe.isCollected)
    } else if (value === 3) {
      recipesArr = recipesHasMarked.filter((recipe) => recipe.isLiked)
    }

    return recipesArr
  }, [celeste, recipes, tabs])

  return (
    <StyledRecipeCards>
      {currentRecipes.length ? (
        <div className="recipe_cards">
          {currentRecipes.map((recipe) => (
            <DIYRecipeCard recipe={recipe} key={recipe.name.en} />
          ))}
        </div>
      ) : (
        <NoMatch />
      )}
    </StyledRecipeCards>
  )
}

const StyledRecipeCards = styled.div`
  .recipe_cards {
    display: flex;
    flex-wrap: wrap;
    padding: 10px 15px;
    @media (max-width: 450px) {
      padding: 10px 0;
    }
  }
`

import React from "react"
import styled from "styled-components"

const HorizontalRule = ()=>{
  return(
  <StyledHorizontalRule>
    <HorizontalRuleIcon />
  </StyledHorizontalRule>)
}

export default HorizontalRule


const StyledHorizontalRule = styled.div`
  padding: 26px;
  display: flex;
  justify-content: center;
  svg{
    width: 300px;
  }
`


const HorizontalRuleIcon = ({ color="var(--acnh-colors-text-primary)" }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" version="1.0" viewBox="0 0 297 14">
      <path fill={color}  d="M20 10.42L33 11 28.002 0zM10 6.97L0 3l.858 9zM43 1l.613 11L53 5.585zm89 13l11-5.867L133.507 1zm67-3.58l13 .58-4.998-11zm-10-3.45L179 3l.858 9zM90.634 1L88 13l12-4.39zM155 13l12-2.4-8.47-9.6zM110 3l2.057 9L118 6.292zm-47 8.215L76 14 71.048 1zM222 1l.613 11L232 5.585zm47.634 0L267 13l12-4.39zM289 3l2.057 9L297 6.292zm-48 8.215L254 14l-4.952-13z" />
    </svg>
  )
}